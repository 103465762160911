import { createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-lg-500px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto" }
const _hoisted_2 = { class: "fv-row mb-10" }
const _hoisted_3 = { class: "fv-plugins-message-container" }
const _hoisted_4 = { class: "fv-help-block" }
const _hoisted_5 = { class: "d-flex flex-wrap justify-content-center pb-lg-0" }
const _hoisted_6 = {
  type: "submit",
  ref: "submitButton",
  id: "kt_password_reset_submit",
  class: "btn btn-lg btn-primary fw-bolder me-4"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Field = _resolveComponent("Field")!
  const _component_ErrorMessage = _resolveComponent("ErrorMessage")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_Form = _resolveComponent("Form")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createCommentVNode("begin::Wrapper"),
    _createElementVNode("div", _hoisted_1, [
      _createCommentVNode("begin::Form"),
      _createVNode(_component_Form, {
        class: "form w-100 fv-plugins-bootstrap5 fv-plugins-framework",
        onSubmit: _ctx.onSubmitForgotPassword,
        id: "kt_login_password_reset_form",
        "validation-schema": _ctx.forgotPassword
      }, {
        default: _withCtx(() => [
          _createCommentVNode("begin::Heading"),
          _cache[3] || (_cache[3] = _createElementVNode("div", { class: "text-center mb-10" }, [
            _createCommentVNode("begin::Title"),
            _createElementVNode("h1", { class: "text-dark mb-3" }, "Forgot Password ?"),
            _createCommentVNode("end::Title"),
            _createCommentVNode("begin::Link"),
            _createElementVNode("div", { class: "text-gray-400 fw-bold fs-4" }, " Enter your email to reset your password. "),
            _createCommentVNode("end::Link")
          ], -1 /* HOISTED */)),
          _createCommentVNode("begin::Heading"),
          _createCommentVNode("begin::Input group"),
          _createElementVNode("div", _hoisted_2, [
            _cache[0] || (_cache[0] = _createElementVNode("label", { class: "form-label fw-bolder text-gray-900 fs-6" }, "Email", -1 /* HOISTED */)),
            _createVNode(_component_Field, {
              class: "form-control form-control-solid lo",
              type: "email",
              placeholder: "Enter Email",
              name: "email",
              autocomplete: "off"
            }),
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createVNode(_component_ErrorMessage, { name: "email" })
              ])
            ])
          ]),
          _createCommentVNode("end::Input group"),
          _createCommentVNode("begin::Actions"),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("button", _hoisted_6, _cache[1] || (_cache[1] = [
              _createElementVNode("span", { class: "indicator-label" }, " Submit ", -1 /* HOISTED */),
              _createElementVNode("span", { class: "indicator-progress" }, [
                _createTextVNode(" Please wait... "),
                _createElementVNode("span", { class: "spinner-border spinner-border-sm align-middle ms-2" })
              ], -1 /* HOISTED */)
            ]), 512 /* NEED_PATCH */),
            _createVNode(_component_router_link, {
              to: "/sign-up",
              class: "btn btn-lg btn-light-primary fw-bolder"
            }, {
              default: _withCtx(() => _cache[2] || (_cache[2] = [
                _createTextVNode("Cancel")
              ])),
              _: 1 /* STABLE */
            })
          ]),
          _createCommentVNode("end::Actions")
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["onSubmit", "validation-schema"]),
      _createCommentVNode("end::Form")
    ]),
    _createCommentVNode("end::Wrapper")
  ], 2112 /* STABLE_FRAGMENT, DEV_ROOT_FRAGMENT */))
}